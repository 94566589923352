.pick-garage-item {
  color: $text-color;
  text-align: center;
  font-size: $font-size-2;
  border: 1px solid $border-color-light;
  border-radius: 8px;
  margin-bottom: 10px;
  display: block;
  padding: 35px 10px;

  &__name{

  }

  &__address{
    font-weight: $font-weight-light;
  }
}