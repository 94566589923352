.one-tree-plant-detail {
    display: table;
    margin-left:10px;

    img {
        max-width: 60px;
        display: table-cell;
        margin-right: 15px;
    }

    p {
        display: table-cell;
        vertical-align: middle;
    }
}
