body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'icomoon';
    src: url('/static/fonts/icomoon.eot?vp6op1');
    src: url('/static/fonts/icomoon.eot?vp6op1#iefix') format('embedded-opentype'),
    url('/static/fonts/icomoon.woff2?vp6op1') format('woff2'),
    url('/static/fonts/icomoon.ttf?vp6op1') format('truetype'),
    url('/static/fonts/icomoon.woff?vp6op1') format('woff'),
    url('/static/fonts/icomoon.svg?vp6op1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-cancell-fill:before {
    content: "\e90d";
}

.icon-check-fill:before {
    content: "\e91c";
}

.icon-file:before {
    content: "\e91d";
}

.icon-info-fill:before {
    content: "\e91e";
}

.icon-send:before {
    content: "\e91f";
}

.icon-warrning-fill:before {
    content: "\e920";
}

.icon-add:before {
    content: "\e900";
}

.icon-audio:before {
    content: "\e901";
}

.icon-back:before {
    content: "\e902";
}

.icon-bell:before {
    content: "\e903";
}

.icon-bin:before {
    content: "\e904";
}

.icon-cancel:before {
    content: "\e905";
}

.icon-check-round:before {
    content: "\e906";
}

.icon-check:before {
    content: "\e907";
}

.icon-chevron-down:before {
    content: "\e908";
}

.icon-chevron-up:before {
    content: "\e909";
}

.icon-chevron-right:before {
    content: "\e909";
    display: inline-block;
    transform: rotate(90deg);
}

.icon-discard:before {
    content: "\e90a";
}

.icon-edit:before {
    content: "\e90b";
}

.icon-eye:before {
    content: "\e90c";
}

.icon-list:before {
    content: "\e90e";
}

.icon-logout:before {
    content: "\e90f";
}

.icon-mic:before {
    content: "\e910";
}

.icon-pause:before {
    content: "\e911";
}

.icon-pending-customer:before {
    content: "\e912";
}

.icon-pending-manager:before {
    content: "\e913";
}

.icon-photo:before {
    content: "\e914";
}

.icon-pie:before {
    content: "\e915";
}

.icon-profile:before {
    content: "\e916";
}

.icon-rec:before {
    content: "\e917";
}

.icon-search:before {
    content: "\e918";
}

.icon-select:before {
    content: "\e919";
}

.icon-settings:before {
    content: "\e91a";
}

.icon-stop:before {
    content: "\e91b";
}
