.settings-tab {

	padding: 0 10px;
	margin-bottom: 50px;

	&__header{
		padding: 10px 0;
		border-bottom: 2px solid $border-color-light;
		margin-bottom: 20px;
	}

}