.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
    float:none !important;
}

.link-style{
    background-color: transparent;
    color: $brand-primary;
    text-decoration: none;
    font-size: $font-size;
    line-height: 27px;
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 0;
        color: darken($brand-primary, 15%);
        text-decoration: none;
    }

    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }
}

.show {
    display: block !important;
}

.hide {
    display: none;
}

.invisible {
    visibility: hidden;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

.underline {
    text-decoration: underline;
}

.pointer {
    cursor: pointer;
}

.pull-l {
    float: left !important;
}

.pull-r {
    float: right !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-3 {
    margin-top: 3px !important;
}
.mt-5 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mh-5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.mh-10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.mh-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.mh-30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
}

.p-0 {
    padding: 0 !important;
}

.pv-30 {
    padding: 0 30px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.static {
    position: static !important;
}

.overflow-hidden {
    overflow: hidden;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-size-xs{
    font-size: $font-size-xs;
}

.font-size-sm{
    font-size: $font-size-sm;
}

.font-size-2{
    font-size: $font-size-2;
}

.font-size-4{
    font-size: $font-size-4;
}

.font-weight-light {
    font-weight: $font-weight-light;
}
.font-weight-regular {
    font-weight: $font-weight-regular;
}
.font-weight-semi-bold, .bold {
    font-weight: $font-weight-semi-bold;
}

.vertical-align-sub{
    vertical-align: sub;
}

.line-height-0 {
    line-height: 0 !important;
}

.text-lighter {
    color: $text-lighter;
}

.centered-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 32px;
    color: $text-light;
    text-align: center;

    div{
        font-size: $font-size;
        font-weight: $font-weight-light;
    }
}

.float-none{
    float: none !important;
}
.float-left{
    float: left !important;
}
.float-right{
    float: right !important;
}

.half-line-divider{
    height: 2px;
    width: 25px;
    margin: 10px 0;
    background-color: #000;
    opacity: 0.8;
}

.no-border{
    border: none !important;
}

.line-height-form-group {
    line-height: 68px;
}
.bottom-sticky-space {
    padding-bottom: 70px;
}
