@import "../../assets/styles/base/_variables";
@import "../../assets/styles/base/_mixins";

.files-upload-list {
    @include clearfix();

    margin-bottom: 10px;
    padding: 20px 0;
    border-top: 1px solid $border-color-light;
    border-bottom: 1px solid $border-color-light;
    overflow-x: auto;
    overflow-y: hidden;
    height: 140px;
    white-space: nowrap;
}

.file-upload-item {
    width: 75px;
    height: 75px;
    text-align: center;
    background-color: #8f9ca5;
    position: relative;
    display: inline-block;
    margin: 0 10px;

    img {
        width: 75px;
        height: 75px;
        vertical-align: baseline;
        object-fit: cover;
    }

    &__action {
        position: absolute;
        background-color: #FFF;
        border-radius: 50px;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 10px;
        box-shadow: 0 1px 1px $text-lighter;
    }

    &__download {
        top: -5px;
        right: 5px;
        color: $text-color !important;
    }

    &__audio {
        background-color: #8f9ca5;

        i {
            font-size: 33px;
            color: #FFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 9999999 !important;

    .ril__outer {
        background-color: #000;
    }
}
