.service-tab {
	padding: 30px 5px 30px;
	background-color: #FFFFFF;
	text-align: center;
	border: 1px solid $border-color-light;
	border-radius: 8px;
	margin-bottom: 15px;
	color: $text-color;
	font-size: $font-size-2;
	line-height: normal;
	display: block;

	&__icon {
		font-size: 40px;
	}

	&__tip {
		font-weight: $font-weight-light;
	}
}

.services-save-footer{
	position: sticky;
	bottom: $footer-icons-height;
	background-color: #FFFFFF;
}

.list-of-prices {

	margin-bottom: 50px;

	&__item{
		padding: 10px 15px 0;
		border-radius: 8px;
		border: 1px solid #f4f4f4;
		font-weight: 300;
		font-size: 12px;
		margin-bottom: 20px;

		&__footer {
			@include clearfix();
			border-top: 1px solid $border-color-light;
			margin: 10px -15px 0;

			& > *{
				padding: 10px 0;
			}
			& > :first-child{
				border-right: 1px solid $border-color-light;
			}

			i {
				font-size: 10px;
			}
		}
	}

}
