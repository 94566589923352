@import "variables";

.form-group {
    position: relative;
    margin: $form-margin;

    label {
        width: 85%;
        word-wrap: break-word;
        display: inline-block;
        color: $label-gray;
        font-size: 8px;
        position: absolute;
        top: 5px;
        left: 20px;
        z-index: 1;
        line-height: 16px;
    }
}

form {
    @include clearfix;
}

select,
input[type="text"],
input[type="email"],
input[type="number"],
input[readonly],
input[type="password"],
input[type="tel"],
textarea,
select::placeholder,
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="number"]::placeholder,
input[readonly]::placeholder,
input[type="tel"]::placeholder,
textarea::placeholder {
    font-weight: 300;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
}

select,
input[type="text"],
input[type="email"],
input[type="number"],
input[readonly],
input[type="password"],
input[type="tel"],
textarea {
    display: block;
    width: 100%;
    border-radius: $border-radius;
    border: $form-input-border;
    background-color: $form-input-background;
    min-height: 36px;
    height: auto;
    padding: 20px 20px 7px 20px;
    outline: 0;

    &[disabled] {
        background-color: $form-input-disabled-background;
    }

    &[disabled] + label {
        color: $form-input-disabled-color-label;
    }

    &[readonly] {
        cursor: default;
    }

    &::placeholder {
        color: $secondary-gray;
    }
}

textarea {
    display: block;
    width: 100%;
    padding: $form-padding;
    color: #000 !important;
    font-weight: bold !important;
    font-size: 15px !important;
    border: 1px solid $border-color;
    font-family: $font-default;
    border-radius: $border-radius;
}

input.input-error,
textarea.input-error {
    border: 1px solid $brand-danger;
    background-color: $form-error-background;
    color: $brand-danger;

    &::placeholder {
        color: $brand-danger;
    }

    & + label {
        color: $brand-danger;
    }
}

input[readonly] {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    user-select: none;

    &:focus {
        border: 0 !important;
    }
}

/*
input[type=file] {
    display: none;
}
*/

.form-group {

    select {
        padding: 20px 20px 10px 20px;
        -webkit-appearance: none;
        background-image: url($select-icon);
        background-size: 10px;
        background-position: calc(100% - 15px) center;
        background-repeat: no-repeat;
    }
}

.has-error {
    font-size: 12px;
    color: $brand-danger;
    margin-top: 0;
    display: inline-block;
    position: absolute;
    text-align: right;
    width: 100%;
}

.has-success {
    font-size: 12px;
    color: $brand-success;
    margin-top: 0;
    display: inline-block;
    position: absolute;
    text-align: right;
    width: 100%;
}


.success-text {
    color: $brand-success;
}

.input-group-addon {
    position: absolute;
    right: 2px;
    background-color: $form-input-addon-background;
    border-radius: 24px;
    text-align: center;
    line-height: 44px;
    padding: 0 15px;
    top: 2px;
    font-weight: $font-weight-light;
    color: $text-light;

    ~ input {
        padding: 20px 60px 7px 20px;
    }
}

.custom-checkbox {
    margin: 10px 0;

    label {
        font-size: $font-size-sm;
        font-weight: 300;
    }
}

.custom-checkbox-label {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: 4px;

    input {
        opacity: 0;
        cursor: pointer;
        z-index: 5;
        position: absolute;
        top: 0;
        left: 0;
    }

    span {
        color: $radio-button-background;
        line-height: 17px;
        font-size: 20px;
    }

    input:checked + span {
        color: $brand-primary;
    }
}
