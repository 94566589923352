@import '../../assets/styles/base/mixins';
@import '../../assets/styles/base/variables';

.Media-Gallery {
  @include clearfix();

  &__container {
    width: 52px;
    height: 52px;
    float: left;
    margin-right: 10px;

    @media screen and (min-width: 360px) {
      width: 62px;
      height: 62px;
    }

    @media screen and (min-width: 400px) {
      width: 76px;
      height: 76px;
    }

    // @media screen and (min-width: 460px) {
    //     width: 86px;
    //     height: 86px;
    // }

    // @media screen and (min-width: 500px) {
    //     width: 95px;
    //     height: 95px;
    // }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--not-image {
      color: #fff;
      position: relative;

      &:hover,
      &:focus {
        color: #fff;
      }

      &.pdf {
        background-color: rgb(190, 46, 46);
      }

      &.video {
        background-color: #000;
      }
    }
  }

  &__title {
    display: inline-block;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
