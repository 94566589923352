@import 'alert-danger';
@import 'alert-success';
@import 'alert-info';

.alert {
  border-radius: 24px;
  font-weight: 400;
  padding: 15px 20px 5px;
  font-size: 12px;
  margin: 15px 0 30px;

  ul{
    font-weight: 300;
    list-style: none;
  }

  &__header{
    margin-bottom: 10px;

    i{
      font-size: $font-size;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}