@import '../../assets/styles/base/variables';
@import '../../assets/styles/base/mixins';

.AC-Request-Status {
    text-align: center;
    padding-bottom: 15px;
    padding-top: 10px;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        background: rgba(0, 0, 0, 0.16);
    }


    &__name {
        padding: 15px 0 0 0;
        position: relative;
        span{
            top:-2px;
            position: relative;
            font-weight: $font-weight-semi-bold;
        }
    }

    &__2, &__3 {

        &:before {
            font-family: 'icomoon', sans-serif;
            font-size: 16px;
            clear: both;
            position: relative;
            margin-right: 10px;
        }
    }

    &__2 {
        color: #ff4d68;

        &:before {
            content: "\e90d";
        }
    }

    &__3 {
        color: #1cd091;

        &:before {
            content: "\e91c";
        }
    }
}

//export const REJECTED_STATUS = 2;
//export const ACCEPTED_STATUS = 3;
