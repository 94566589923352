.submit-button__container {
    margin-right: auto;
    margin-left: auto;
    position: fixed;
    bottom: 0px;
    background: #fff;
    width: 100%;
}

.submit-button__button {
    background-color: #2dafff;
    color: #fff;
    border: 1px solid #2dafff;
    margin: 10px 0;
    border-radius: 31px;
    text-align: center;
    display: inline-block;
    outline: 0 !important;
    box-sizing: border-box;
    -webkit-transition: .2s ease-in;
    transition: .2s ease-in;
    font-weight: 300;
    line-height: normal;
    width: 100%;
    font-size: 16px;
    padding: 14px 28px !important;
    cursor: pointer;
    margin-bottom: 20px;
}

.submit-button__button[disabled] {
    box-shadow: none;
    background-color: rgba(0,0,0,.3);
    border: rgba(0,0,0,.3);
    color: #fff !important;
    cursor: not-allowed;
}

.submit-button__background {
    width: 100%;
    background: #fff;
}
