@import "../../base/variables";
@import "../../base/mixins";

.popup-overlay{
	position: fixed;
	top: 0;
	left: 0;
	background-color: #000000c2;
	width: 100%;
	height: 100%;
	z-index: 1000;
	display: none;
}

.popup{
	width: 85%;
	background-color: #FFF;
	border-radius: 10px;
	padding: 15px;
	position: absolute;
	top: 50%;
    transform: translateY(-50%) translateX(-50%);
	left: 50%;

	&__header{
		@include clearfix();
		margin-bottom: 30px;

		&__title{
			font-size: $font-size-2;
			font-weight: $font-weight-semi-bold;
			float: left;
			width: 80%;
		}

		&__close{
			float: right;
			text-align: right;
			width: 10%;
		}
		&__subtitle{
			font-weight: $font-weight-light;
			font-size: $font-size-sm;
			clear: both;
			padding-top: 10px;
		}
	}

	&__content{

	}
}
